<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" :refresh="silentRefresh" :css_class="(userData != null) ? '' : 'position-relative'">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title">{{ $t('param') }}</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div v-if="userData != null" class="cfa-page">
          <!-- banner1 -->
          <div class="cfa-block p-4">
            <div class="d-flex align-items-center justify-content-between">
              <h1 class="cfa-section-title mb-0">{{ $t('my_information') }}</h1>
              <div class="d-flex">
                <a style="border-color: #d2dcf7; border-width: 2px;" class="btn align-self-center btn-secondary btn-discret d-none d-md-block">
                  <i class="fa fa-user-circle margin-right-10" aria-hidden="true"></i>&nbsp;COMPTE {{ (userData.type_account.toString().toUpperCase() == "ORGANISATION_USER") ? "INVITÉ - ORGANISATION" : userData.type_account.toString().toUpperCase() }}
                </a>
                <a @click="openFileSelector" class="ps-2 ms-2 btn align-self-center cfa-btn-primary">
                  <i v-if="!uploadPending" class="fa fa-image margin-right-10 d-none d-md-inline white-color " aria-hidden="true">&nbsp;</i>
                  <span v-if="!uploadPending" class="d-none d-md-inline ">{{ $t('update_avatar') }}</span>
                  <span v-if="!uploadPending" class="d-md-none d-inline">Avatar</span>
                  <div v-if="uploadPending" class="" style="width: 11em;">
                    <img  width="20" src="@/assets/img/gif/load2.gif" alt="loader"/>
                  </div>
                </a>
                <input type="file" ref="fileSelector" @change="uploadImage" style="display: none;">
              </div>
            </div>

            <section class="text-center py-4" style="border-radius: 0.5em;">
              <div >
                <img class="bg-white p-1" width="150" height="150" style="border-radius: 100%;" :src="(userData.image_url != null) ? userData.image_url : defaultImage" alt="logo company"/>
              </div>
            </section>

            <section class="mt-4">
              <div class="row">
                <div class="col-12 col-md-4 pl-0">
                  <div class="form-group ">
                    <label class="control-label" for="inputFirstname">{{$t('first_name')}}</label>
                    <input id="inputFirstname" class="form-control" name="inputFirstname" type="text" v-model="firstname">
                  </div>
                </div>
                <div class="col-12 col-md-4 pl-0">
                  <div class="form-group ">
                    <label class="control-label" for="inputLastname">{{$t('last_name')}}</label>
                    <input id="inputLastname" class="form-control" name="inputLastname" type="text" v-model="lastname">
                  </div>
                </div>
                <div class="col-12 col-md-4 pl-0 pr-0">
                  <div class="form-group ">
                    <label class="control-label" for="inputEmail">E-mail</label>
                    <input id="inputEmail" class="form-control" name="inputEmail" type="email" :value="userData.email" disabled="">
                  </div>
                </div>
              </div>
            </section>
            <section class="mt-4">
                <div class="row">
                  <div class="col-12 col-md-4 pl-0">
                    <div class="form-group ">
                      <label class="control-label" for="inputFirstname">{{$t('personal_phone')}}</label>
                      <input id="inputFirstname" class="form-control" name="inputFirstname" type="text" v-model="phonePersonal">
                    </div>
                  </div>
                  <div class="col-12 col-md-4 pl-0">
                    <div class="form-group ">
                      <label class="control-label" for="inputLastname">{{$t('professional_phone')}}</label>
                      <input id="inputLastname" class="form-control" name="inputLastname" type="text" v-model="phoneProfessional">
                    </div>
                  </div>
                  <div class="col-12 col-md-4 pl-0 pr-0">
                    <div class="form-group ">
                      <label class="control-label" for="inputEmail">Entreprise</label>
                      <input id="inputEmail" class="form-control" name="inputEmail" type="email" :value="userData.organisation_detail?.name" disabled="">
                    </div>
                  </div>
                </div>
            </section>
            <div class="mt-4 d-flex justify-content-between align-items-center">
              <div class="text-left">
                {{ $t('valid_information') }}
              </div>
              <button @click="updateUserProfil" id="validateButton-pass" class="btn btn-round cfa-btn-primary" type="submit">
                {{ $t('continue') }}
              </button>
            </div>
          </div>
          <br>

          <!-- banner3
          <div class="cfa-block p-4 mt-4">
            <h1 class="cfa-section-title">MON COMPTE</h1><br>
            <section class="padding-top-20 pb-0">
              <form id="general" class="" role="tabpanel">
                <div id="general" class="tab-pane active" role="tabpanel">
                  <div class="col-sm-6 col-md-12 pl-0">
                    <div class="form-group ">
                      <label class="control-label" for="inputFirstname">Type de compte</label>
                      <input id="inputFirstname" class="form-control" name="inputFirstname" type="text" :value="userData.type_account.charAt(0).toUpperCase() + userData.type_account.slice(1)" disabled>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div> -->

          <!-- banner4 -->
          <div class="cfa-block p-4">
            <h4>{{ $t('changing_password') }}</h4><br>
            <div>
              <div class="tab-pane">
                <div class="col-12 pl-0">
                  <div class="form-group ">
                    <label class="control-label" for="inputPassnew">{{$t('old_password')}}</label>
                    <div class="input-group">
                      <input v-model="oldPassword" autocomplete="new-password" class="form-control" name="inputPassnew" type="password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$" required=""><span class="ptxt icon wb-eye" ></span>
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label" for="inputPassnew">{{$t('new_password')}}</label>
                    <div class="input-group">
                      <input v-model="newPassword" autocomplete="new-password" class="form-control" name="inputPassnewOne" type="password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$" required=""><span class="ptxt icon wb-eye" ></span>
                    </div>
                  </div>
                  <br>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="text-left">
                      {{ $t('enter_password') }}
                    </div>
                    <button @click="tryChangePassword" class="btn btn-round cfa-btn-primary" type="submit">
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- banner5 -->
          <div class="cfa-block p-4 mt-4">
            <h4>{{$t('language_settings')}}</h4><br>
            <div>
              <div class="tab-pane">
                <form class="langForm">
                  <input v-if="$i18n.locale == 'fr'" checked class="radioLang" type="radio" id="contactChoice1" name="lang" value="fr" v-model="$i18n.locale" @change="changeLang">
                  <input v-else class="radioLang" type="radio" id="contactChoice1" name="lang" value="fr" v-model="$i18n.locale" @change="changeLang">
                  <label for="contactChoice1" style="position: relative; bottom: 1.1em; margin-right: 1em; margin-left: .5em; cursor: pointer;">
                    <img width="25" class="mr-4" src="../../assets/img/svg/lang/fr.svg" alt="">
                  </label>

                  <input v-if="$i18n.locale == 'en'" no-disabled checked class="radioLang" type="radio" id="contactChoice2" name="lang" value="en" v-model="$i18n.locale" @change="changeLang">
                  <input v-else class="radioLang" no-disabled type="radio" id="contactChoice2" name="lang" value="en" v-model="$i18n.locale" @change="changeLang">
                  <label for="contactChoice2" style="no-pointer-events: none; position: relative; bottom: 1.1em; margin-right: 1em; margin-left: .5em; cursor: pointer;">
                    <img width="25" class="mr-4" src="../../assets/img/svg/lang/en.svg" alt="">
                  </label>
                </form>
              </div>
            </div>
          </div>
          

          <!-- <div class="cfa-block p-4 mt-4">
            <h4>PARAMETRES DE LANGUE</h4><br>
            <div>
              <div class="tab-pane">
                <form class="langForm">
                  <input checked class="radioLang" type="radio" id="contactChoice1" name="lang" value="fr">
                  <label for="contactChoice1" style="position: relative; bottom: 1.1em; margin-right: 1em; margin-left: .5em; cursor: pointer;">
                    <img width="25" class="mr-4" src="../../assets/img/svg/lang/fr.svg" alt="">
                  </label>

                  <input class="radioLang" type="radio" id="contactChoice2" name="lang" value="en" disabled>
                  <label for="contactChoice2" style="no-pointer-events: none; position: relative; bottom: 1.1em; margin-right: 1em; margin-left: .5em; cursor: pointer;">
                    <img width="25" class="mr-4" src="../../assets/img/svg/lang/en.svg" alt="">
                  </label>
                </form>
              </div>
            </div>
          </div> -->

          <!-- banner6 -->


        </div>
        <pre-loader v-else></pre-loader>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
// eslint-disable-next-line
import { getAPI, guestAPI } from '@/axios-api.js'
import PreLoader from "@/components/shared/PreLoader.vue";

export default {
  name: 'ParameterView',
  components: {
    CFAFooter,
    CFANav,
    PreLoader
  },
  data () {
    return {
      userData: null,
      alertString: null,
      defaultImage: " /images/defaultImage.png",
      firstname: '...',
      lastname: '...',
      phonePersonal: '...',
      phoneProfessional: '...',
      oldPassword: '',
      newPassword: '',
      silentRefresh: null,
      uploadPending: false,
      role: '',
      primary: "red",
      secondary: "yellow",
    }
  },
  methods:{
    changeLanguage(obj){
      localStorage.setItem('language',obj.target.value)
    },
    retrieveUserData () {
      getAPI.get('accounts/users/me')
        .then(response => {
          if(response.status == 200) {
            this.userData = response.data.data
            this.firstname = this.userData.firstname
            this.lastname = this.userData.lastname
            this.role = this.userData.role
            this.phonePersonal = this.userData.phone
            this.phoneProfessional = this.userData.phone
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    tryChangePassword () {
      if(this.oldPassword.length > 2) {
        /*guestAPI.post('accounts/login',
          {
            email: this.userData.email,
            password: this.oldPassword,
          })
        .then(response => {
            if(response.status == 200) {
              // send password forget mail
              guestAPI.post('accounts/forgot-password', {
                email: this.userData.email,
              })
              .then(response => {
                if(response.status == 200) {
                  //this.alertString = "Veuillez vérifier votre adresse e-mail pour changer le mot de passe.|alert-yes|6000::"+Date.now()
                }
                else {
                  //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
                }
              })
              .catch(() => {
                //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
              })
              // -------------------------
            }
            else {
              //this.alertString = `Votre mot de passe erroné. Etes-vous ${localStorage.firstname} ${localStorage.lastname}?|alert-no|6000::`+Date.now()
            }
          })
        .catch(() => {
            //this.alertString = `Votre mot de passe erroné. Etes-vous ${localStorage.firstname} ${localStorage.lastname}?|alert-no|6000::`+Date.now()
        })*/
        getAPI.post('accounts/change-password',
          {
            current_password: this.oldPassword,
            new_password: this.newPassword,
          }).then(res => {
            console.log(res)
            if (res.status == 200) {
              this.alertString = "Mot de passe mise à jour .|alert-yes|6000::"+Date.now()
            }
          })
          .catch(err => {
            console.log(err)
            if(err.status == 400) {
              this.alertString = `Mot de passe errone |alert-no|6000::`+Date.now()
            }
          })
      }
      else{
        //this.alertString = "Veuillez remplir correctement le champ.|alert-no|5000::"+Date.now()
      }
    },
    openFileSelector() {
      this.$refs.fileSelector.click();
    },
    async uploadImage() {
      const file = this.$refs.fileSelector.files[0];
      const formData = new FormData();
      formData.append('image_url', file);

      this.uploadPending = true
      getAPI.put(`accounts/users/upload-profile/${localStorage.user}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((payload) => {
          this.userData.image_url = payload.data.data.image_url
          //this.alertString = "Profil mis a jours avec succès.|alert-yes|5000::"+Date.now()
          this.silentRefresh = Date.now();
          this.uploadPending = false
        }).catch((err) => {
          //this.alertString = "Fichier trop large.|alert-no|5000::"+Date.now()
          this.uploadPending = false
          console.log(err)
        });
    },
    updateUserProfil () {
      if(this.firstname != "" && this.lastname != "" && this.phonePersonal.length > 5) {
        getAPI.put(`accounts/users/${localStorage.user}/`, {
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phonePersonal,
          //role: this.role
        }).then(response => {
          if(response.status == 200) {
            localStorage.setItem('firstname', this.firstname)
            localStorage.setItem('lastname', this.lastname)
            localStorage.setItem('phone', this.phonePersonal)
            this.alertString = "Profil mis a jour avec succès|alert-yes|5000::"+Date.now()
            this.silentRefresh = Date.now();
            // this.$router.go(0);
          }
          else{
            this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
          }
        }).catch(() => {
          this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
        })
      }
      else{
        this.alertString = "Veuillez remplir les champs correctement.|alert-no|5000::"+Date.now()
      }
    },
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    retrieveOrg() {
      getAPI.get(`organisations/${localStorage.organisation}/`)
        .then(response => {
          if (response.status == 200) {
            this.organisationData = response.data
            this.primary = this.organisationData.primary_color;
            this.secondary = this.organisationData.secondary_color;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    saveColor() {
      const payload = {
          primary_color: this.primary_color,
          secondary_color: this.secondary_color,
          tertiary_color: this.tertiary_color,
      };
      getAPI.put(`organisations/change_color/${localStorage.organisation}/`, payload)
        .then(response => {
          if (response.status === 200) {
            console.log('response', response);
            if(response.data.data){
              // localStorage.setItem('primaryColor', this.primary);
              // localStorage.setItem('secondaryColor', this.secondary);
              localStorage.setItem('tertiaryColor', response.data.data.tertiary_color);
            }
            this.alertString = "Color changed successfully |alert-yes|5000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('respose',error.response);
            this.alertString = `Upgrade your plan for change color|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    activeColor() {
      const primaryColor = this.primary;
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = this.secondary;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    async setPrimaryColor() {
      this.primary_color = this.primary;
      this.saveColor()
      const actualColor = this.primary;
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', actualColor);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    async setSecondaryColor() {
      this.secondary_color = this.secondary;
      this.saveColor();
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
    
  },
  mounted () {
    this.retrieveUserData()
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.form-group label{
  margin-bottom: 0.5em;
  font-weight: 400;
}
</style>
