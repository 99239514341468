<template>
  <div class="cfa-wrapper wrapper" >
    <div class="bg-layer"></div>
    <!-- sidenav -->
    
    <nav class="cfa-navbar no-collapse side-navig" id="cfa-side-navbar" style="padding: 0px;padding-top: 0!important">
      <a v-if="showLogo && showFavicon" class="py-2 pt-0 d-flex justify-content-center w-100" href="/dashboard">
        <img class="cfa-side-logo cfa-logo w-100" :src="showFavicon"
          alt="cyberforceacademy logo" style="border-radius: 6px; width:auto; max-height: 150px; object-fit: cover; margin: 0 auto" >
        <img class="cfa-side-logo cfa-favicon-logo" src="../../assets/Icons/Favicon1.png"
          alt="cyberforceacademy logos" style="">
      </a>
      <a v-else class="pt-10 pb-2 d-flex justify-content-center w-100" href="/dashboard">
        <img class="cfa-side-logo cfa-logo" src="../../assets/brand/spec-brand-1.svg"
          alt="cyberforceacademy logo" style="height: 80px; ">
        <!-- <img class="cfa-side-logo  cfa-favicon-logo" src="../../assets/brand/cfa-favicon1.png" alt="cyberforceacademy logo" style=""> -->
      </a>
      <div class="nav-container d-flex flex-column" style="">
        <ul class="cfa-navbar-ul text-light mt-4 p-0">
          <nav-button :menuMode="menuMode" routeName="Dashboard" :label="$t('sidebar.dashboard')" class="" style="margin-left: -2px;">
            <template v-slot:icon>
              
              <img class="gIcon nav-icon" width="24" src="../../assets/Icons/menu_svg/Dashboard.svg"
              alt="button dashbaord icon" />
            </template>
          </nav-button>
          
          <nav-button :menuMode="menuMode" routeName="Module" :label="$t('sidebar.modules')" class=""
            :moreCss="(['ModuleIframe', 'ModulePreview'].includes(this.$route.name)) ? 'cfa-link-active' : ''"
            style="margin-left: -2px;">
            <template v-slot:icon>
              <img class="gIcon" width="24" src="../../assets/Icons/menu_svg/Module.svg" alt="button dashbaord icon" />
            </template>
          </nav-button>
         
          <nav-button no-style="pointer-events: none; opacity: 0.5;" :menuMode="menuMode" routeName="Quiz" :label="$t('sidebar.exam')" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/QuizFinal.svg"
                alt="button dashbaord icon" />
            </template>
          </nav-button>
          <nav-button :menuMode="menuMode" routeName="Certificate" :label="$t('sidebar.certificate')" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/Certificate.svg"
                alt="button dashbaord icon" />
            </template>
          </nav-button>

          <hr style="color: #132B4D;" v-if="viewFilter(['organisation'])">
          <nav-button v-if="viewFilter(['organisation'])" :menuMode="menuMode" routeName="Microlearn" :label="$t('sidebar.microlearn')" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/Menu_Icons/White_Icon/e-learning.png" alt="button dashbaord icon"/>
            </template>
          </nav-button>
          <nav-button v-if="viewFilter(['organisation'])" :menuMode="menuMode" routeName="Phishing" :label="$t('sidebar.audit')" class="" :moreCss="(['PhishingDetails'].includes(this.$route.name)) ? 'cfa-link-active':''">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/CampagnePhishing.svg"
                alt="button dashbaord icon" />
            </template>
          </nav-button>

          <hr style="color: #132B4D;" v-if="viewFilter(['organisation'])">
          <nav-button v-if="viewFilter(['organisation'])" :menuMode="menuMode" routeName="UserProgress"
            :label="$t('sidebar.follow')" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/SuiviUtilisateurs.svg"
                alt="button dashbaord icon" />
            </template>
          </nav-button>
          <nav-button v-if="viewFilter(['organisation'])" :menuMode="menuMode" routeName="UserManage"
            :label="$t('sidebar.manage')" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/GestionUtilisateurs.svg"
                alt="button dashbaord icon" />
            </template>
          </nav-button>
          <nav-button v-if="viewFilter(['organisation'])" :menuMode="menuMode" routeName="Entreprise"
            :label="$t('sidebar.enterprise')" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/Enterprise.svg"
                alt="button dashbaord icon" />
            </template>
          </nav-button>

          <hr style="color: #132B4D;" v-if="viewFilter(['organisation'])">
          
          
          <!-- <nav-button v-if="viewFilter(['organisation', 'individual'])" :menuMode="menuMode" routeName="Facturation"
            :moreCss="(['Facturation', 'FacturationPricing'].includes(this.$route.name)) ? 'cfa-link-active' : ''"
            :label="$t('facturation')" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/Facturation.svg"
                alt="button dashbaord icon" />
            </template>
          </nav-button>
          <hr style="color: #132B4D;"> -->


          <nav-button :menuMode="menuMode" routeName="Setting" :label="$t('sidebar.settings')" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/Parametres.svg"
                alt="button dashbaord icon" />
            </template>
          </nav-button>
          <!-- <nav-button :menuMode="menuMode" routeName="Audit logs" label="Audit logs"
             class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/audit.png" alt="button dashbaord icon" />
            </template>
          </nav-button> -->
          <nav-button :menuMode="menuMode" routeName="Support" :label="$t('sidebar.help')"
            :moreCss="(['Support', 'SupportDetail'].includes(this.$route.name)) ? 'cfa-link-active' : ''" class="">
            <template v-slot:icon>
              <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/Support.svg" alt="button dashbaord icon" />
            </template>
          </nav-button>
          <nav-button :menuMode="menuMode" routeName="Logout" :label="$t('sidebar.logout')"
            @click.prevent="openLogoutBox = true" :isLink="false" class="cfa-primary">
            <template v-slot:icon>
              <!-- <img class="gIcon" width="20" src="../../assets/Icons/menu_svg/Logout.svg" alt="button dashbaord icon" /> -->
              <i class="fa fa-sign-out"></i>
            </template>
          </nav-button>
          <br><br>
          <div class="text-center hidden">
            <button @click="changeMenuMode2" class="btn cfa-btn-primary btn-chevron-left" id="cfa-nav-bottom-swiper">
              <i v-if="this.menuMode == 'LARGE'" class="fa fa-chevron-left white-color"></i>
              <i v-else class="fa fa-chevron-right white-color"></i>
            </button>
          </div>
        </ul>
      </div>
    </nav>

    <div class="d-flex flex-column cfa-root-content">
      <!-- top nav bar -->
      <div class="cfa-root-head d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div class="text-center">
            <button @click="changeMenuMode2" class="btn cfa-btn-primary btn-icon" id="cfa-nav-bottom-swiper">
              <i class="fa fa-bars"></i>
            </button>
            <!--
            <button @click="changeMenuMode" class="btn cfa-btn-primary cfa-menu-mobile me-2" id="cfa-menu-swiper">
              <i class="fa fa-bars"></i>
            </button>
            -->
          </div>
          <!--<search-bar cssStyle="padding: 1.2em 1.3em 1.2em 3.1em; height: 3em;"></search-bar>-->
        </div>
        
        <div class="d-flex align-items-center">
          <select-button @action="changeLang" :dropDownLabel="['🇨🇵️ French', '🇺🇸️ English']" :dropDownLink="['fr', 'en']" uid="cfa-lang-swiper-bottom" :showArrow="false" cssStyle="border: none !important; position: relative !important;right: 1em;width: 10em;bottom: 0em;" cssClass="d-block"></select-button>
          <button @click="openNotifBox = true" class="cfa-discret-btn">
            <img width="15" src="../../assets/Icons/All_Icon/notif.png" alt="notification icon" />
          </button>
          <avatart-type-1 @getLogoutConfirmatiion="openLogoutBox = true" :refresh="refresh"
            :image="defaultAvatar"></avatart-type-1>
        </div>
      </div>

      <!-- content -->
      <div :class="'cfa-root-body ' + css_class">
        <slot name="title"></slot>
        <slot name="content"></slot>
      </div>

      <!-- include -->
      <slider-popup @close="openNotifBox = false" :open="openNotifBox" uid="cfa-slider-001"></slider-popup>
      <transition name="fade" appear mode="out-in">
        <scalable-modal display-mode="flex" @callback="tryLogout" :notifyTaskDone="callbackPayload"
          @close="openLogoutBox = false" :open="openLogoutBox" :advancedControl="true" validationButtonText="Oui"
          exitButtonText="Non" :style="'width: 30em; '" uid="cfa-modal-001">
          <template v-slot:content>
            <!--<p class="mb-0" style="font-size: 3em;"> ⚠️ </p>-->
            <div>
              <img src="@/assets/img/gif/logout.gif" style="width: 9em;" />
            </div>
            <h2 class="mb-0">Deconnexion</h2>
            <p>Êtes vous sûre?</p>
            <div><br><br></div>
          </template>
        </scalable-modal>
      </transition>
    </div>
    <transition name="fadeY" appear mode="out-in">
      <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
    </transition>
    <transition name="fadeY" appear mode="out-in">
      <alert v-if="errorMessage && (errorMessage != '')" :status="'alert-no'" :message="errorMessage"></alert>
    </transition>
  </div>
</template>

<script>
import NavButton from '@/components/shared/button/NavButton.vue'
import AvatartType1 from '@/components/shared/avatar/AvatartType1.vue'
import SelectButton from '@/components/shared/button/SelectButton.vue'
import SliderPopup from '@/components/shared/alert/SliderPopup.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import { getAPI } from '@/axios-api.js'
import Alert from '@/components/shared/alert/Alert.vue'
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: 'CFANav',
  setup () {
    const store = useStore();
    const errorMessage = computed(() => store.state.error.errorMessage);
    return {
      errorMessage,
      store
    }
  },
  components: {
    NavButton,
    AvatartType1,
    SliderPopup,
    ScalableModal,
    Alert,
    SelectButton
  },
  props: {
    alert: {
      type: String,
      default: null
    },
    css_class: {
      type: String,
      default: null
    },
    refresh: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      openNotifBox: false,
      openLogoutBox: false,
      menuMode: 'LARGE',
      menuMode2: 'LARGES',
      showLogo: '',
      primary:'',
      organisationData:{},
      secondary:'',
      showFavicon: '',
      callbackPayload: null,
      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
      waitingAPIResponse: false,
      defaultAvatar: '/images/defaultImage.png',
      typeAccount: localStorage.getItem('type_account')
    }
  },
  methods: {
    async retrieveUserSubscription() {
      this.waitingAPIResponse = true
      await getAPI.get(`subscriptions/find-subscription`).then(response => {
        if (response.status == 200) {
          console.log(response.data)
          this.subscriptionData = response.data.data
          this.showLogo = this.subscriptionData.organisation.logo;
          this.showFavicon = this.subscriptionData.organisation.favicon;
          this.$store.dispatch("loader/triggerLoading", false);
          

          //console.log("Tatata: ", this.subscriptionData)
        }
        else {
          // //let msg = 'Oups ! something went wrong.'
          ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          this.$store.dispatch("loader/triggerLoading", false);
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch(error => {
        this.$store.dispatch("loader/triggerLoading", false);
        if (error.response) {
          //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          // //let msg = 'Oups ! something went wrong.'
          ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    },
    viewFilter(array) {
      return (array.includes(this.typeAccount)) ? true : false
    },
    displayError(error, type = 'alert-ok', time = 5000) {
      this.gAlertMessage = error
      this.gAlertType = type
      this.alertMe = true
      setTimeout(() => {
        this.alertMe = false
      }, time);
    },
    changeMenuMode() {
      if (this.menuMode2 == 'LARGE') {
        this.menuMode2 = 'SHORT';
        this.menuMode = 'SHORT'
        document.getElementById('cfa-side-navbar').style.width = "60px"
        document.getElementById('cfa-side-navbar').style.display = "none"
      }
      else {
        this.menuMode2 = 'LARGE';
        this.menuMode = 'LARGE';
        this.showFavicon = ''
        document.getElementById('cfa-side-navbar').style.width = "24vw"
        document.getElementById('cfa-side-navbar').style.display = "block"
      }
    },
    changeMenuMode2() {
      if (this.menuMode == 'LARGE') {
        document.getElementById('cfa-side-navbar').classList.remove('no-collapse');
        document.getElementById('cfa-side-navbar').classList.add('collapsed');
        document.getElementById('cfa-side-navbar').style.width = "60px"
        document.querySelector('.cfa-root-content').style.width = "calc(100vw - 60px)"
        this.menuMode = 'SHORT';
      } else if (this.menuMode == 'SHORT') {
        this.menuMode = 'LARGE';
        document.getElementById('cfa-side-navbar').classList.remove('collapsed');
        document.getElementById('cfa-side-navbar').classList.add('no-collapse');
        document.getElementById('cfa-side-navbar').style.width = "24vw"
        document.querySelector('.cfa-root-content').style.width = "calc(100vw - 24vw)"
      }
      console.log(this.menuMode)
    },
    tryLogout() {
      getAPI.post('accounts/logout', {
        refresh_token: localStorage.getItem('refresh_token'),
      })
        .then(response => {
          if (response.status == 204) {
            localStorage.clear();
            this.callbackPayload = 'A bientôt' + '::' + Date.now();
            setTimeout(() => {
              this.waitingAPIResponse = false
              let a = document.createElement('a');
              a.href = "/"
              a.click()
              a.remove()
            }, 2000);
            //window.location.href = "https://www.google.com";
          }
          else {
            this.displayError('Oups ! something went wrong.', 'alert-no')
            this.openLogoutBox = false
          }
        })
        .catch(error => {
          localStorage.clear();
          setTimeout(() => {
            this.waitingAPIResponse = false
            let a = document.createElement('a');
            a.href = "/"
            a.click()
            a.remove()
          }, 2000);
          if (error.response.status == 400) {
            // error
          } else if (error.response) {
            this.displayError(error.response.data.message, 'alert-no', 10000)
          }
          else if (error.request) {
            this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
          }
          else {
            this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
          }
          this.openLogoutBox = false
        })
    },
    isValidHex (color) 
    {
      let pattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/ 
      return pattern.test(color)
    },
    async retrieveOrg() {
      const storedPrimary = localStorage.getItem("primary_color");
      const storedSecondary = localStorage.getItem("secondary_color");

      if (storedPrimary && this.isValidHex(storedPrimary) && storedSecondary && this.isValidHex(storedSecondary)) {
        // If colors are in localStorage, use them
        this.activeColor(storedPrimary, storedSecondary);
      } else {
        // Fetch from API if not found in localStorage
        try {
          const response = await getAPI.get(`organisations/${localStorage.organisation}/`);
          
          if (response.status === 200) {
            this.organisationData = response.data;
            console.log("dataorg:", this.organisationData);
            
            const primary = this.organisationData?.primary_color;
            const secondary = this.organisationData?.secondary_color;
            if (primary && this.isValidHex(primary) && secondary && this.isValidHex(secondary)) {
              localStorage.setItem("primary_color", primary);
              localStorage.setItem("secondary_color", secondary);
              // Store colors in localStorage
              // Apply colors
              this.activeColor(primary, secondary);
            }

          }
        } catch (error) {
          console.error("Error fetching organization data:", error);
        }
      }
    },
    activeColor(color1,color2) {
      const primaryColor = color1;
      console.log("color:",color1);
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = color2;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    changeLang (e) {
      var lang = 'FR';
      
      if (e == "en") {
        lang = 'EN';
      }
      localStorage.setItem('language', e)
    
      getAPI.put(`accounts/users/${localStorage.user}/`, {
        firstname: localStorage.firstname,
        lastname: localStorage.lastname,
        phone: localStorage.phone,
        position: null,
        language: lang
      })
      .then(response => {
        if (response.status == 200) {
          //this.alertString = "Profil mis a jour avec succès|alert-yes|5000::"+Date.now()
          if(localStorage.language == 'en' && e == "fr") {
          localStorage.setItem('language', 'fr')
          }
          if(localStorage.language == 'fr' && e == "en") {
              localStorage.setItem('language', 'en')
          }
          this.$i18n.locale = localStorage.language
          this.lang = (localStorage.language == 'en') ? 'en' : 'fr'
        }
        else {
          this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
        }
      }).catch(() => {
        this.alertString = "Veuillez remplir convenablement les champs.|alert-no|5000::"+Date.now()
      })
        // alert('ok')
    },
  },
  created() {
    //this.retrieveOrg();
  },
  async beforeMount () {
    
  },
  async mounted() {
    //this.changeMenuMode();
    // this.activeColor();
    this.retrieveOrg();
    await this.retrieveUserSubscription();

    if (!['ExamIframe'].includes(this.$route.name)) {
      const script = document.createElement('script')
      script.src = 'https://cdn.jsdelivr.net/gh/yasserelsaid/chatbot@latest/index.min.js'
      script.id = 'botnet-docx-k9-bwex22'
      document.body.appendChild(script);
    }

    const checkChatbaseButton = () => {
      const chatbaseButton = document.getElementById('chatbase-bubble-button')
      if (chatbaseButton) {
        chatbaseButton.style.background = '#06bbc4'
        return
      }
      setTimeout(checkChatbaseButton, 1000)
    }
    checkChatbaseButton()
  },
  beforeUnmount() {
    this.$store.dispatch("loader/triggerLoading", true);
  },
  // eslint-disable-next-line
  /*async beforeRouteEnter(to, from, next) {
    // Run retrieveOrg before the component is created
    console.log("first routing here")
    /*const storedPrimary = localStorage.getItem("primary_color");
    const storedSecondary = localStorage.getItem("secondary_color");

    if (!storedPrimary || !storedSecondary) {
      await getAPI.get(`organisations/${localStorage.organisation}/`)
        .then(response => {
          if (response.status === 200) {
            const primary = response.data.primary_color;
            const secondary = response.data.secondary_color;

            // Store in localStorage
            localStorage.setItem("primary_color", primary);
            localStorage.setItem("secondary_color", secondary);

            // Apply colors globally
            document.documentElement.style.setProperty('--primary-color', primary);
            document.documentElement.style.setProperty('--secondary-color', secondary);
          }
        })
        .catch(error => {
          console.error("Error fetching organization data:", error);
        });
    } else {
      // Apply stored colors directly
      document.documentElement.style.setProperty('--primary-color', storedPrimary);
      document.documentElement.style.setProperty('--secondary-color', storedSecondary);
    }
    //next();
  },*/
  beforeRouteLeave() {
    this.$store.dispatch("loader/triggerLoading", true);

  }, 
  computed: {
    isSpecificPage() {
      return this.$route.path === '/dashboard' || this.$route.path === '/gestion-utilisateurs' || this.$route.path === '/campagne-de-phishing';
    },
    whatMenuMode() {
      return this.menuMode
    }
  },
  watch: {
    alert: function () {
      if (this.alert == null)
        return
      let payload = this.alert.split('::')[0].split('|')
      this.displayError(payload[0], payload[1], parseInt(payload[2], 10));
    },
  }
}
</script>

<style scoped>
.cfa-menu-mobile {
  padding: .6em .9em;
}

.cfa-side-logo {
  width: 90%;
  padding: 0em;
}

.cfa-wrapper {
  min-height: 100vh;
  background-color: #f6f9fe;
  display: flex;
  padding: 1.2em;
}

.cfa-navbar {
  width: 24vw;
  background-color: #ffffff;
  padding: 20px 10px;
  min-height: 100vh;
  position: sticky;
  top: 0;
  box-shadow: 3px 30px 50px -2px rgba(1, 11, 60, .1);
}
.cfa-navbar img {
  max-width: unset;
}

.nav-container {
  padding: 20px 10px;
}

.for-mobile {
  display: none;
}

@media only screen and (max-width: 991px) {
  .cfa-navbar {
    width: 60px;
  }
  .nav-container {
    padding: 20px 0px;
  }
}

@media only screen and (max-width: 600px) {
  #cfa-side-navbar {
    display: none;
  }
  .cfa-navbar {
    width: 60px;
  }
  .for-mobile {
    display: block;
  }

  .for-desktop {
    display: none;
  }

  .hidden {
    display: none;
  }
}

.cfa-root-content {
  padding: 0px 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .cfa-root-content {
    width: calc(100vw - 26vw);
    display: flex;
    flex-direction: column;
  }
}

.cfa-discret-btn {
  border: none;
  background-color: transparent;
  border-radius: .5em;
}

.cfa-discret-btn:hover {
  border: 1px solid #fff;
}

.cfa-discret-btn {
  background-color: #fff;
}

.cfa-favicon-logo {
  position: relative;
  left: 0.3em;
  height: auto !important;
}

.cfa-root-body {
  min-height: calc(100vh - 60px);
}

.cfa-go-back:hover {
  background-color: #585f73;
  color: #fff;
}

/* Grisage de Ressources et Rendez-vous expert */
.disabled {
  position: relative;
  cursor: allowed;
}

.disabled::before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.disabled:hover::before {
  visibility: visible;
  opacity: 1;
}
.collapsed .cfa-logo{
  display: none !important;
}
.no-collapse .cfa-favicon-logo{
  display: none !important;
}
</style>
